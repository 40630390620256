@import url('https://fonts.googleapis.com/css?family=Oxygen|Raleway&display=swap');
@font-face {
  src: url('https://fonts.googleapis.com/css?family=Oxygen|Raleway&display=swap');
  /* TITle */
  font-family: 'Oxygen', sans-serif;
  /* Text */
  font-family: 'Raleway', sans-serif;
}

$primary: #34F5D7;
$warning: #FFE814;
$danger: #94090D;
$success: #43A047;
$white: #ECF0F1;
$dark: #2C3E50;

.butn {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
  box-shadow: 0px 3px 10px rgba(25,17,34,0.05);


  &:hover {
    transform: translateY(-3px)
  }
 
}

// Button Colors & Styles

.butn-primary-solid {
  background-color: $primary;
  color: $white;
  border: none;
}
.butn-warning-solid {
  background-color: $warning;
  color: $white;
  border: none;
}
.butn-danger-solid {
  background-color: $danger;
  color: $white;
  border: none;
}
.butn-success-solid {
  background-color: $success;
  color: $white;
  border: none;
}
.butn-primary-outline {
  background-color: transparent;
  color: $primary;
  border: 2px solid $primary;
}
.butn-warning-outline {
  background-color: transparent;
  color: $warning;
  border: 2px solid $warning;
}
.butn-danger-outline {
  background-color: transparent;
  color: $danger;
  border: 2px solid $danger;
}
.butn-success-outline {
  background-color: transparent;
  color: $success;
  border: 2px solid $success;
}


// Button sizes

.butn-med {
  padding: 10px 20px;
  font-size: 18px;
}
.butn-sm {
  padding: 7px 15px;
  font-size: 12px;
}

.butn-xsm {
  padding: 2px 10px;
  font-size: 12px;
  
}

.butn-xsm:link, .butn-xsm:visited, .butn-sm:link, .butn-sm:visited {
  text-decoration: none;
  color: $primary
}

.button-link {
  text-decoration: none;
  color: $primary

}

