.about-wrapper-mob {
  padding: 3em 1em;
  // height: 114em !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  // border: red solid 2px;
  
  @media only screen and (min-device-width : 375px) and (max-device-width : 768px) {
    
    height: 105em;
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1370px) {
    height: 126em;

  }
}

.about-title-mob h1 {
  font-weight: bold;
  font-size: 4em;
  color: black;
  // margin-top:  .4em;
  margin-bottom: .6em;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    font-size: 5em;
    margin-bottom: 1em;

  }

}

.about-words-wrapper-mob {
  margin-bottom: 2em;
  padding-top: 1em;
  // height: 100%;
  // border: 2px solid red;
}