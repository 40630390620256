.language-cont-mob {
  padding: 2em .5em;
  // height: 90em !important;
  width: 100%;
  // background-color: rgb(231, 255, 253);
  background-color: #f1f3f3;
  color: black;
  padding-top: 3em;

  // border: green 1px solid;
}

.skills-head-mob h1 {
  text-align: center;
  font-size: 4em;
  padding-bottom: .5em;
  font-weight: bold;
}

// ------- SKILL CARD MOB --------------

.skills-mob{
  display: flex;
  width: 100%;
  padding: 0em 2em;
  height: 80%;
  flex-wrap: wrap;
  justify-content: space-around;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    // border: 2px red solid;
    width: 100%;
    text-align: center;
    justify-content: center;
    
  }

  
}

.skill-wrapper-mob {
  width: 40%;
  // border: 2px blue solid;
  text-align: center;
  // padding: 1em;
  margin: .5em;
}

.skill-icons-mob {
  font-size: 6em;
  margin: .5em 0em;

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    font-size: 6em;

  }
}