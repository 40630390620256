.contact-cont {
  padding: 5em 20em;
  // width: 75%;
  background-color: rgb(231, 255, 253);
  background-color: #f1f3f3;

  
}

.contact_item {
  padding-bottom: 2em;
  /* font-size: 14px; */
  font-size: 1.5em;
  font-weight: bold;
  width: 70%;
  margin: 0 auto;
  color: #34F5D7;

}

.contact_item textarea, .contact_item input {
  outline: none;
  border: none;
  border-bottom: solid 1px #ccc;
  padding: 1.5vw;
  width: 100%;
}

.contact_item input:hover, textarea:hover {
  border-bottom: solid 1px #34F5D7;
}

.contact_item input, .contact_item label {
  display: block;
  // background-color: rgb(231, 255, 253);
  background-color: #f1f3f3;
}

.contact_item textarea {
  height: 10em;
  // background-color: rgb(231, 255, 253);
  background-color: #f1f3f3;
}

.contact-heading {
  text-align: center;
}

.contact h2 {
  margin-bottom: 3vw;
  // font-size: 4em;
  // text-align: center;
}

.contact-me {
  font-weight: bold;
  font-size: 3em;
}

.contact p {
  font-size: 2em;
  // text-align: center;
}

.contact-me-subhead {
  font-size: 2em;
  padding-bottom: 1em;
}

.contact_logo {
  width: 100%;
  max-width: 30em;
  margin-bottom: 3vh;
  height: auto;
}

.address_info {
  font-weight: bold;
}

.address_info p {
  margin: 0.5em;
}

// --------  Contact Button

.contact-btn-div {
  text-align: center;
}

.contact_submit {
  font-size: 2em;
  background-color: black;
  color: white;
  padding: .5em;
  border-radius: 10px;
  box-shadow: 0px 3px 10px rgba(25,17,34,0.05);

}

.contact_submit:hover {
  background-color: white;
  color: black;
}

.contact_right:hover {
  box-shadow: 0px 8px 16px 0px rgba(155, 149, 149, 0.2);
}