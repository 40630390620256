@import url('https://fonts.googleapis.com/css?family=Oxygen|Raleway&display=swap');
@font-face {
  src: url('https://fonts.googleapis.com/css?family=Oxygen|Raleway&display=swap');
  /* TITle */
  font-family: 'Oxygen', sans-serif;
  /* Text */
  font-family: 'Raleway', sans-serif;
}



.hero-cont-mob {
  position: relative;
  width: 100%;
  height: 95vh;
  // min-height: 35rem;
  // padding: 15rem 0;
  margin: 10px;
  background: 
  linear-gradient(
    to right bottom,
    white,
    rgba(211, 211, 211, 0.80)),
    url('https://res.cloudinary.com/dz7yojyef/image/upload/v1628180435/portfolio/background-2462430_1920_mfoglu.jpg'),
    -webkit-linear-gradient(
      fade-out(black, 0.1),
      fade-out(black, 0.5)
      );
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;


  .center-col-mob {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 80vh;
    padding-top: 5em;
    
    // border: 2px blue solid;
    text-align: center;
  }
  
  h1 {
    // font-family: 'Varela Round';
    font-family: 'Oxygen', sans-serif;
    font-size: 5rem;
    line-height: 5.5rem;
    letter-spacing: 0.8rem;
    animation-name: moveInLeft !important;
    animation-duration: 2s;
    background: -webkit-linear-gradient(
      fade-out(black, 0.1),
      fade-out(black, 0.5)
      );
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      // border: 2px green solid;
      padding-bottom: .2em;
    }
    .typed-hero-mob {
      font-family: 'Raleway', sans-serif;

      font-size: 2.5rem;
      font-weight: bold;
      // letter-spacing: 0.8rem;
    background: -webkit-linear-gradient(
      fade-out(black, 0.1),
      fade-out(black, 0.5)
      );
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      // border: 2px green solid;
  }

  @keyframes moveInLeft {
    0% {
      opacity: 0;
      transform: translateX(-100px);
    }
    80% {
      transform: translateX(20px);
      
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    /* Styles */
    .center-col-mob {
      background-attachment: fixed;
    }
    h1 {
      font-size: 8rem;
      line-height: 7.5rem;

    }
    .typed-hero-mob {
      font-size: 3rem;
    }
    }

}




