.about-pic-mob-main {
  display: inline;
  margin: 0 auto;
  // margin-left: 2%;
  height: 100%;
  width: auto;
  box-shadow: 0px 3px 10px rgba(25,17,34,0.05);

}
.about-pic-mob {
  display: inline;
  margin: 0 auto;
  margin-left: -17%;
  height: 100%;
  width: auto;
  box-shadow: 0px 3px 10px rgba(25,17,34,0.05);
  

}

.img-cropper-mob {
  width: 17em;
  height: 17em;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  // border: 
  margin: 0 auto;
  margin-bottom: .2em;
  box-shadow: 0px 3px 10px rgba(25,17,34,0.05);


  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    width: 22em;
    height: 22em;

  }

}