@import url('https://fonts.googleapis.com/css?family=Oxygen|Raleway&display=swap');
@font-face {
  src: url('https://fonts.googleapis.com/css?family=Oxygen|Raleway&display=swap');
  /* TITle */
  font-family: 'Oxygen', sans-serif;
  /* Text */
  font-family: 'Raleway', sans-serif;
}

// ---------- Projects Mob -----------

.port-cont-mob {
  // height: 70em;
  text-align: center;
  padding: 1.5em;

  h2 {
    font-family: 'Raleway', sans-serif;
    font-weight: '900';
    margin-bottom: 0.5em;
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    height: 110vh;

  }
}

.projects-mob {
  // display: flex;
  // width: 30%;
  justify-content: space-around;
}

.proj-header-mob h2 {
  font-family: 'Raleway', sans-serif;
  font-size: 4em;
  padding: 2em;
  color: black;
  font-weight: bold;
  // border: red solid 2px;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    font-size:  5em;
  }

  
}

// --------- Project Mob ----------------

.project-image-mob {
  width: 95%;
  height: 50vh;
  cursor: pointer;
  
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    height: 50em;
    
  }
}

.proj-head-mob {
  font-size: 1.5em;
  padding-bottom: 1em;
  // border: 1px green solid;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    font-size: 2.2em;
    padding: 1em 2.5em;
    line-height: 1.85em;
  }
}

.project-cont-mob {
  margin-top: 3em;
  padding: 2em .5em 0em .5em;
  // height: 55em;
  // box-shadow: 0 6px 10px 0 rgba(0,0,0,0.2);
  box-shadow: 
    1rem 1rem 2rem rgba(0, 0, 0, 0.1), 
    -1rem -1rem 2rem rgba(255, 255, 255, 0.5);
  transition: 0.3s;

  &:hover {
    // box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    box-shadow: 
    inset 1rem 1rem 2rem rgba(0, 0, 0, 0.1),
    inset -1rem -1rem 2rem rgba(255, 255, 255,       0.5);
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    h2 {
      font-size: 3em;
      text-decoration: underline;
    }
  }
}

.icon-proj-cont {
  display: flex;
  justify-content: space-evenly;
  // border: 1px solid red;

  .project-icons-mob {
    font-size: 4em;
    margin: .2em 0em;

  }

}



.proj-butn-mob {
  margin-bottom: 1em;
  display: flex;
  justify-content: space-evenly;

}

.button-link
.button-link:visited {
  text-decoration: none;
  color: #04756F;
}